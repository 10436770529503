import React from 'react';
import PropTypes from 'prop-types';

import Form from 'react-bootstrap/Form';

import EditableColumn from './EditableColumn';

export default class MasterVariationColumn extends EditableColumn {
  constructor(props) {
    super(props);

    this.onChangeActiveEditorValue = this.onChangeActiveEditorValue.bind(this);
  }

  onChangeActiveEditorValue(event) {
    this.props.setHiddenField(this.props.keys, event.target.value);

    super.onChangeActiveEditorValue(event);
  }

  render() {
    return (
      <>
        <dt className="col-md-4">{this.props.title}</dt>
        <dd className="col-md-8">
          {this.props.editable && this.state.activeEditor ? (
            <Form.Group>
              <Form.Control type="text" value={String(this.state.value)} onChange={this.onChangeActiveEditorValue} />
              <p className="small text-danger mt-2">{this.state.invalidMessage}</p>
            </Form.Group>
          ) : String(this.state.value)}
          {this.viewEditableButton()}
        </dd>
      </>
    );
  }
}

MasterVariationColumn.defaultProps = {
  editable: false
};
MasterVariationColumn.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  keys: PropTypes.arrayOf(PropTypes.any),
  editable: PropTypes.bool,
  setHiddenField: PropTypes.func.isRequired
};
