import React from 'react';
import PropTypes from 'prop-types';

import EditableColumn from './EditableColumn';

export default class ColorColumn extends EditableColumn {
  render() {
    return (
      <>
        <dt className="col-12 col-md-3">カラー</dt>
        <dd className="col-12 col-md-9">
          {this.props.name}
          {this.props.hex ? (
            <span className="__colortip" style={{ backgroundColor: this.props.hex }} />
          ) : null}
        </dd>
      </>
    );
  }
}

ColorColumn.defaultProps = {
  hex: ''
};
ColorColumn.propTypes = {
  name: PropTypes.string.isRequired,
  hex: PropTypes.string
};
