import React from 'react';
import PropTypes from 'prop-types';
import EditableColumn from './EditableColumn';

export default class StandardColumn extends EditableColumn {
  render() {
    return (
      <>
        <dt className="col-12 col-md-3">{this.props.title}</dt>
        <dd className="col-12 col-md-9">{this.props.value}</dd>
      </>
    );
  }
}

StandardColumn.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};
