import React from 'react';
import PropTypes from 'prop-types';

import Form from 'react-bootstrap/Form';
import EditableColumn from '../MasterProducts/EditableColumn';

export default class MasterVariationColumn extends EditableColumn {
  render() {
    return (
      <>
        {this.props.editable && this.state.activeEditor ? (
          <Form.Group>
            <Form.Control type="text" value={String(this.state.value)} onChange={this.onChangeActiveEditorValue} />
            <p className="small text-danger mt-2">{this.state.invalidMessage}</p>
          </Form.Group>
        ) : (
          <>
            { String(this.state.value) }
            <input type="hidden" value={this.state.value} name={this.props.fieldName} />
          </>
        )}
        {this.viewEditableButton()}
      </>
    );
  }
}

MasterVariationColumn.prop_types = {
  field_name: PropTypes.string.isRequired
};
