import React from 'react';
import PropTypes from 'prop-types';

import Form from 'react-bootstrap/Form';

import EditableColumn from './EditableColumn';

export default class MasterProductColumn extends EditableColumn {
  formFieldName() {
    return `bulk_imports[master_products][][${this.props.fieldName}]`;
  }

  render() {
    return (
      <>
        <dt className="col-md-3">{this.props.title}</dt>
        <dd className="col-md-9">
          {this.props.editable && this.state.activeEditor ? (
            <Form.Group>
              <Form.Control type="text" value={this.state.value} onChange={this.onChangeActiveEditorValue} />
              <p className="small text-danger mt-2">{this.state.invalidMessage}</p>
            </Form.Group>
          ) : this.state.value}
          {this.viewEditableButton()}
        </dd>
        <input type="hidden" value={this.state.value} name={this.formFieldName()} />
      </>
    );
  }
}

MasterProductColumn.defaultProps = {
  editable: false
};

MasterProductColumn.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  editable: PropTypes.bool
};
