import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';

const BLANK_INVALID_MESSAGE = '登録したい値を入力してください';
export default class EditableColumn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeEditor: false,
      invalidMessage: null,
      value: this.props.value // eslint-disable-line react/no-unused-state
    };

    this.toggleActiveEditor = this.toggleActiveEditor.bind(this);
    this.onChangeActiveEditorValue = this.onChangeActiveEditorValue.bind(this);
  }

  onChangeActiveEditorValue(e) {
    const { value } = e.target;

    let invalidMessage = null;
    if (!value) invalidMessage = BLANK_INVALID_MESSAGE;

    this.setState({ value, invalidMessage }); // eslint-disable-line react/no-unused-state
  }

  viewEditableButton() {
    if (!this.props.editable) return null;

    if (this.state.invalidMessage) {
      return <span className="text-muted small ml-2">{this.activeEditorLabel()}</span>;
    }
    return <Button className="small ml-2" variant="link" size="sm" role="button" onClick={this.toggleActiveEditor}>{this.activeEditorLabel()}</Button>;
  }

  toggleActiveEditor() {
    if (this.state.activeEditor && this.state.invalidMessage) return false;

    this.setState((preState) => ({ activeEditor: !preState.activeEditor }));

    return true;
  }

  activeEditorLabel() {
    return this.state.activeEditor ? '登録' : '変更';
  }
}

EditableColumn.defaultProps = {
  editable: false
};

EditableColumn.propTypes = {
  value: PropTypes.string.isRequired,
  editable: PropTypes.bool
};
